/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:2ad99b9b-ad2f-4138-905c-b7d9fbf53743",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_6BUDsNszm",
    "aws_user_pools_web_client_id": "57lfvs7duueu22n4gcesonmf45",
    "oauth": {}
};


export default awsmobile;
