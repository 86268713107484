import React from "react";
import { Authenticator } from "aws-amplify-react";
import "@aws-amplify/ui/dist/style.css";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import {signUpConfig} from "../SignUp/index"

const Login = () => {
  const { loggedIn } = useAuth();
  const onStateChange = (s) => {
    console.log("STATE CHANGED", s);
  };
  return loggedIn ? (
    <Redirect to={"/"} />
  ) : (
    /* @ts-ignore:  */
    <Authenticator onStateChange={onStateChange} usernameAttributes={"email"} signUpConfig={signUpConfig}/>
  );
};
export default Login;