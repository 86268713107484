import React, { useEffect } from "react";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { Hub } from "aws-amplify";

interface AuthState {
  auth: CognitoUser | null;
}

export const AuthContext = React.createContext<AuthState>({
  auth: null,
});

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProps) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const [auth, setAuth] = React.useState<CognitoUser | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setAuth(user);
    });
  }, []);
  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      const { event, data } = payload;
      if (event === "signOut") {
        setAuth(null);
        return;
      }
      setAuth(data);
    });
  }, []);
  return {
    auth,
    loggedIn: "username" in (auth || {}),
  };
}
