import React from "react";
import { Authenticator} from "aws-amplify-react";
import "@aws-amplify/ui/dist/style.css";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";


export const signUpConfig = {
  header: 'My Customized Sign Up',
  hideAllDefaults: true,
  defaultCountryCode: '44',
  signUpFields: [
    {
      label: 'First name',
      key: 'name',
      required: true,
      displayOrder: 1,
      type: 'name'
    },
    {
      label: 'Last name',
      key: 'family_name',
      type: 'family_name',
      displayOrder: 2,

    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 3,
      type: 'password'
    },
    // {
    //   label: 'PhoneNumber',
    //   key: 'phone_number',
    //   required: true,
    //   displayOrder: 3,
    //   type: 'string'
    // },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 4,
      type: 'string'
    }
  ]
};

const SignUp = () => {
  const { loggedIn } = useAuth();
  const onStateChange = (s) => {
    console.log("STATE CHANGED, logged in?", s);
  };
  return loggedIn ? (
    <Redirect to={"/"} />
  ) : (

    /* @ts-ignore:  */
    <Authenticator authState={"signUp"} onStateChange={onStateChange} usernameAttributes={"email"} signUpConfig={signUpConfig}/>
  );
};
export default SignUp;